export const MESSAGES = {
  LOGIN: "Please login to continue.",
  LOGGED_OUT: "Your are now logged out.",
  SUCCESS: "Change(s) you made are saved successfully.",
  ERROR:
    "There is some problem in processing your request. Please try again later.",
  SUBSCRIBED: "You have successfully subscribed.",
  DELIVERY_SUBMITTED: "Your delivery has been submitted",
  SUBSCRIBERS_ACCESSPTED: "Subscriber(s) accepted.",
  SUBSCRIBERS_REJECTED: "Subscriber(s) rejected.",
  PAYMENT_CREATED: "Your payment created successfully!",
  WITHDRAWL_METHOD_UPDATED: "Withdrawl method updated successfully!",
  BUSINESS_LOGO_UPDATED: "Business logo added!",
  LOCATOIN_UPDATE: "Business locatation updated!",
  CAMPAIGN_CREATED: "Your Campaign has been created!",
  OFFER_CREATED: "Your Offer has been created!",
  PIN_SENT: "Verification PIN sent to your registered Email Address.",
};
